import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { CompanyTypes } from 'types/companyTypes'
import api from '../services/api'
import { OccurrenceDay } from '../types/occurrenceDay'
import { routes } from '../utils/constants'
import handleMessageError from '../utils/handleMessageError'
import { useCompanies } from './useCompanies'
import { useToast } from './useToast'
import useToggle from './useToggle'

const OccurrenceDayContext = createContext<any>(null)

const OccurrenceDayProvider = ({ children }: PropsWithChildren) => {
  const { isOpen: loading, openToggle, closeToggle } = useToggle()

  const { companies } = useCompanies()
  const [activeCompanies, setActiveCompanies] = useState<CompanyTypes[]>([])
  const [messages, setMessages] = useState<any>([])
  const [endOfList, setEndOfList] = useState<boolean>(false)
  const [totalMessages, setTotalMessages] = useState<number>(0)
  const [totalMessagesNotRead, setTotalMessagesNotRead] = useState<number>(0)
  const monitoringOccurrencesMatch = useMatch(routes.MONITORING_OCCURRENCES)

  const {
    isOpen: updateFirebaseOccurrence,
    openToggle: activeFirebaseOccurrence,
    closeToggle: disableFirebaseOccurrence,
  } = useToggle()
  const [soundOccurrence, setSoundOccurrence] = useState<any>(true)
  const { toastError } = useToast()

  const handleChangeMessage = useCallback(
    ({ data, page }: any) => {
      if (page === 0) {
        setTotalMessagesNotRead(data?.total_messages_not_read)
        setTotalMessages(data?.total_messages)
        setMessages(data?.messages)
        setEndOfList(false)
      } else {
        if (data?.messages.length === 0) {
          setEndOfList(true)
        }
        setMessages([...messages, ...(data?.messages || [])])
      }
    },
    [messages]
  )

  const getOcurrence = useCallback(
    async (page: any) => {
      try {
        openToggle()
        const { data } = await api.get(
          `monitoring/messages/all/matched/today?companies=${activeCompanies.map(c => c.id).join(',')}&page=${page}`
        )
        handleChangeMessage({ data, page })
      } catch (e: any) {
        const messageError = Object.values(e.response.data).toString()
        toastError('Erro na busca de ocorrencias', messageError)
      } finally {
        closeToggle()
      }
    },
    [activeCompanies, handleChangeMessage]
  )

  useEffect(() => {
    if (activeCompanies.length === 0) {
      setActiveCompanies(companies)
    } else {
      const validIds = new Set(companies.map((company: CompanyTypes) => company.id))
      const areAnyInvalid = activeCompanies.some((company: CompanyTypes) => !validIds.has(company.id))
      if (areAnyInvalid) {
        setActiveCompanies(companies)
      }
    }
  }, [companies])

  const toggleSoundOccurrence = () => {
    setSoundOccurrence(!soundOccurrence)
  }

  const updateActiveCompanies = useCallback(
    (companyIds: number[]) => {
      const newCompanies = companies.filter((company: CompanyTypes) => companyIds.includes(company.id))
      setActiveCompanies(newCompanies)
      setMessages([])
      setEndOfList(false)
      setTotalMessages(0)
      setTotalMessagesNotRead(0)
    },
    [companies]
  )

  const handleMarkAllRead = async () => {
    checkMessageAllRead()
    if (activeCompanies.length > 0) {
      try {
        await api.post(`monitoring/messages/mark/matched/today`, { companies: activeCompanies.map(c => c.id) })
      } catch (e: any) {
        console.log('Erro ao buscar licitações.', handleMessageError(e))
      }
    }
  }
  const checkMessageAllRead = () => {
    setTotalMessagesNotRead(0)
    const newMessages = messages.map((item: OccurrenceDay) => {
      return { ...item, is_read: true }
    })
    setMessages(newMessages)
  }

  const checkMessage = async (id: number) => {
    setTotalMessagesNotRead(totalMessagesNotRead - 1)
    const newMessages = messages.map((item: OccurrenceDay) => {
      if (item.id === id) {
        return { ...item, is_read: true }
      }
      return item
    })
    setMessages(newMessages)
  }

  const updateMessageBiddingMatched = async (id: number, bidding: any) => {
    await checkMessage(id)
    try {
      await api.patch(`monitoring/messages/mark_message_as_read/${bidding}`, { messages_id: [id] })
    } catch (e: any) {
      console.log('Erro ao buscar licitações.', handleMessageError(e))
    }
  }

  return (
    <OccurrenceDayContext.Provider
      value={{
        totalMessages,
        totalMessagesNotRead,
        activeCompanies,
        loading,
        handleChangeMessage,
        setActiveCompanies,
        activeFirebaseOccurrence,
        disableFirebaseOccurrence,
        updateMessageBiddingMatched,
        handleMarkAllRead,
        updateFirebaseOccurrence,
        soundOccurrence,
        getOcurrence,
        toggleSoundOccurrence,
        updateActiveCompanies,
        messages,
        endOfList,
      }}
    >
      {children}
    </OccurrenceDayContext.Provider>
  )
}

const useOccurrenceDay = () => {
  return useContext(OccurrenceDayContext)
}

export { OccurrenceDayProvider, useOccurrenceDay }
