import { CompanyProvider } from 'hooks/useCompanies'
import { LoadingProvider } from 'hooks/useLoading'
import { MessageBiddingProvider } from 'hooks/useMessageBidding'
import { MonitoringFirebaseProvider } from 'hooks/useMonitoringFirebase'
import { ProposalListProvider } from 'hooks/useProposalList'
import { SettingsProposalProvider } from 'hooks/useSettingsProposal'
import { NoticesProvider } from 'hooks/useNotices'
import { ToastProvider } from 'hooks/useToast'
import { UserProvider } from 'hooks/useUser'
import { PropsWithChildren } from 'react'
import { ToastContainer } from 'react-toastify'
import { BidStatusProvider } from '../hooks/useBidStatus'
import { CenterNotificationProvider } from '../hooks/useCenterNotification'
import { CredentialsProvider } from '../hooks/useCredentials'
import { MonitoringBiddingProvider } from '../hooks/useMonitoringBidding'
import { OccurrenceDayProvider } from '../hooks/useOccurrenceDay'
import { SettingsProspectionProvider } from '../hooks/usePropectionSetting'
import { ProposalProvider } from '../hooks/useProposal'
import { ProposalNewProvider } from '../hooks/useProposalNew'
import { ProposalProgressProvider } from '../hooks/useProposalProgress'
import { PropspectionSearchProvider } from '../hooks/usePropspectionSearch'
import { SettingMonitoringProvider } from '../hooks/useSettingsMonitoring'
import { PortalProvider } from '../hooks/userPortal'

const Providers = ({ children }: PropsWithChildren) => {
  return (
    <LoadingProvider>
      <ToastProvider>
        <MonitoringFirebaseProvider>
          <UserProvider>
            <CompanyProvider>
              <CredentialsProvider>
                <BidStatusProvider>
                  <PortalProvider>
                    <SettingsProposalProvider>
                      <SettingsProspectionProvider>
                        <NoticesProvider>
                          <MonitoringBiddingProvider>
                            <OccurrenceDayProvider>
                              <CenterNotificationProvider>
                                <SettingMonitoringProvider>
                                  <PropspectionSearchProvider>
                                    <MessageBiddingProvider>
                                      <ProposalProvider>
                                        <ProposalListProvider>
                                          <ProposalProgressProvider>
                                            <ProposalNewProvider>{children}</ProposalNewProvider>
                                          </ProposalProgressProvider>
                                        </ProposalListProvider>
                                      </ProposalProvider>
                                    </MessageBiddingProvider>
                                  </PropspectionSearchProvider>
                                </SettingMonitoringProvider>
                              </CenterNotificationProvider>
                            </OccurrenceDayProvider>
                          </MonitoringBiddingProvider>
                        </NoticesProvider>
                      </SettingsProspectionProvider>
                    </SettingsProposalProvider>
                  </PortalProvider>
                </BidStatusProvider>
              </CredentialsProvider>
            </CompanyProvider>
          </UserProvider>
        </MonitoringFirebaseProvider>
      </ToastProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </LoadingProvider>
  )
}

export default Providers
