// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios'
import { USER_CONFIG } from 'utils/constants'
import { deleteItem, getItem } from 'utils/persistenceUtils'

const api = axios.create({
  baseURL: process.env.REACT_APP_URL,
})

const defaultsHeadersAxios = (token: string | null) => {
  if (token) {
    api.defaults.headers.common.Authorization = `Token ${token}`
  } else {
    delete api.defaults.headers.common.Authorization
  }
}

const userStr = getItem(USER_CONFIG)
if (userStr) {
  const user = JSON.parse(userStr)
  const { token } = user
  if (token) defaultsHeadersAxios(token)
}

api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === 401) {
      deleteItem(USER_CONFIG)
      if (window.location.pathname !== '/login') window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)

export { defaultsHeadersAxios, api }

export default api
