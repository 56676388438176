import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import api from 'services/api'
import { proposalProps } from '../types/propostaTypes'
import { MODALIDADES } from '../utils/constants'
import { formatDateTimeStamp, formatDateTimeStampAsX } from '../utils/formatDate'
import { useCompanies } from './useCompanies'
import { useToast } from './useToast'
import { useUser } from './useUser'

const ProposalNewContext = createContext<any>(null)

const ProposalNewProvider = ({ children }: PropsWithChildren) => {
  const { company } = useCompanies()
  const [status, setStatus] = useState<any[]>([])
  const [listProposal, setListProposal] = useState<proposalProps[]>([])
  const [listProposalFinish, setListProposalFinish] = useState<proposalProps[]>([])
  const [pages, setPages] = useState(1)
  const [pagesFinish, setPagesFinish] = useState(1)
  const [totalFinish, setTotalFinish] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingCreateProposal, setLoadingCreateProposal] = useState(false)
  const { toastError, toastSuccess } = useToast()
  const { loginState } = useUser()

  const getListProposalFinishCadastradas = async () => {
    try {
      const {
        data: { proposals, total },
      } = await api.get(`/proposal/all/by_company/${company?.id}?page=${pagesFinish - 1}&status_id=7,9,10`)
      setListProposalFinish(
        proposals.map((proposta: any) => ({
          ...proposta,
          modality: MODALIDADES.find(item => item.id.toString() === proposta.modality)?.value || '----------------',
          dispute_date_timestamp: proposta.dispute_date,
          dispute_date: formatDateTimeStamp(proposta.dispute_date),
        }))
      )
      if (pages === 1 && total) {
        setTotalFinish(total)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllStatusProposal = async () => {
    try {
      const { data } = await api.get(`/proposal/all/status`)
      setStatus(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (loginState === 'LOGGED') getAllStatusProposal()
  }, [loginState])

  const createProposal = async ({ bid_code, buyer_code, modality }: any) => {
    try {
      const { data } = (await api.post('/proposal/create/new_law', {
        company: company?.id,
        bid_code,
        buyer_code,
        modality: MODALIDADES.find(item => item.id === modality.id)?.id || '--------',
      })) as any
      setListProposal([data, ...listProposal])
    } catch (data: any) {
      toastError('Não foi possivel gerar planilha!', 'Veriquei se ja existe planilha gerada com esse dados')
    }
  }

  const deleteProposalList = (id: number) => {
    setListProposal(listProposal?.filter(proposta => proposta?.id !== id))
  }
  const deleteProposalListFinish = (id: number) => {
    setListProposalFinish(listProposalFinish?.filter(proposta => proposta?.id !== id))
  }

  const getListProposal = async (loading?: boolean) => {
    setLoading(loading || false)
    try {
      const {
        data: { proposals, total },
      } = await api.get(`/proposal/all/by_company/${company?.id}?page=${pages - 1}`)
      setListProposal(
        proposals.map((proposta: any) => ({
          ...proposta,
          modality: MODALIDADES.find(item => item.id.toString() === proposta.modality)?.value || '----------------',
          dispute_date_timestamp: proposta.dispute_date,
          dispute_date: formatDateTimeStamp(proposta.dispute_date),
        }))
      )
      if (pages === 1 && total) {
        setTotal(total)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const downloadSheet = async (id: string | number) => {
    try {
      const {
        data: { spreadsheet },
      } = await api.get(`/proposal/spreadsheet/${id}`)
      const link = document.createElement('a')
      link.href = spreadsheet[0].url_generated_spreadsheet
      link.setAttribute('download', spreadsheet[0].name_generated_spreadsheet)
      document.body.appendChild(link)
      link.click()
      toastSuccess('Sucesso', 'Planilha baixada com sucesso')
    } catch (e) {
      toastError('Desculpe', 'Não foi possivel baixar a planilha')
      console.log(e)
    }
  }

  const registerProposal = async (sheetId: string, calbak?: any) => {
    setLoadingCreateProposal(true)
    try {
      await api.get(`/proposal/start_upload_new_law/${sheetId}`)
      toastSuccess('Proposta iniciada com sucesso!', 'Agora você acompanhar o andamento da proposta')
      // eslint-disable-next-line no-unused-expressions
      calbak && calbak()
    } catch (e) {
      toastError('Erro ao iniciar proposta', 'Tente novamente mais tarde!')
    } finally {
      setLoadingCreateProposal(false)
    }
  }

  return (
    <ProposalNewContext.Provider
      value={{
        status,
        deleteProposalList,
        downloadSheet,
        getListProposal,
        listProposal,
        createProposal,
        registerProposal,
        total,
        setPages,
        pages,
        getListProposalFinishCadastradas,
        deleteProposalListFinish,
        listProposalFinish,
        loadingCreateProposal,
        pagesFinish,
        setPagesFinish,
        totalFinish,
      }}
    >
      {children}
    </ProposalNewContext.Provider>
  )
}

const useProposalNew = () => {
  return useContext(ProposalNewContext)
}

export { ProposalNewProvider, useProposalNew }
