import { useCompanies } from 'hooks/useCompanies'
import { useToast } from 'hooks/useToast'
import useToggle from 'hooks/useToggle'
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import api from 'services/api'
import { emptyInput, validetValueError } from 'utils/emptyInput'

const initialState = {
  portal: '',
  company: '',
  username: '',
  password: '',
}

interface ContractModule {
  contract: number
  id: number
  module: number
  quota: number
}

interface ContractProps {
  company: number
  created_at: number
  ends_in: number
  id: number
  is_valid: boolean
  modules: ContractModule[]
}

const CredentialsContext = createContext<CredentialsProps>({} as CredentialsProps)

export interface CredentialsProps {
  isFetching: boolean
  updateCredentials: () => void
  allCredentialsAreValid: boolean
  areAnyCredentialsValid: boolean
  credentials: any
  createCredential: () => void
  getCredentials: () => void
  handleMessageError: (key: any) => void
  handleValues: (target: any) => void
  handleValuesEdit: (credential: any) => void
  resetInputValue: () => void
  validateValue: () => boolean
  getByContractById: (id: number) => void
  isComprasNetCredentialValid: boolean
  error: any
  inputValue: any
  contracts: ContractProps | undefined
  isValidContract: boolean
  contractEndsIn: Date | undefined
  isFreeContract: boolean
}

const CredentialsProvider = ({ children }: PropsWithChildren) => {
  const { company } = useCompanies()
  const [contracts, setContracts] = React.useState<ContractProps>()

  const [credentials, setCredentials] = useState<any>([])

  const { toastError } = useToast()

  const [inputValue, setInputValue] = useState({ ...initialState, company: company?.id })

  const { isOpen: isFetching, openToggle, closeToggle } = useToggle()

  const [error, setError] = useState<any>(initialState)

  const allCredentialsAreValid = useMemo(() => {
    if (!company || !credentials) return true

    const validation = credentials.filter((credentials: any) => credentials.company === company.id)

    if (validation.length === 0) return true

    return validation.every((credential: any) => credential.is_valid)
  }, [company, credentials])

  const areAnyCredentialsValid = useMemo(() => {
    if (!company || !credentials) return false

    const validation = credentials.filter((credentials: any) => credentials.company === company.id)

    if (validation.length === 0) return false

    return validation.some((credential: any) => credential.is_valid)
  }, [company, credentials])

  const isComprasNetCredentialValid = useMemo(() => {
    if (!company || !credentials) return false

    const validation = credentials.filter((credentials: any) => credentials.company === company.id)

    if (validation.length === 0) return false

    return validation.some((credential: any) => credential.portal === 1 && credential.is_valid)
  }, [company, credentials])

  const isValidContract = useMemo(() => {
    return contracts?.is_valid ?? false
  }, [contracts])

  const isFreeContract = useMemo(() => {
    if (!contracts) return false
    return contracts.ends_in === null && contracts.is_valid
  }, [contracts])

  const contractEndsIn = useMemo((): Date | undefined => {
    if (!contracts) return undefined
    if (contracts.ends_in) {
      return new Date(contracts.ends_in * 1000)
    }
    return undefined
  }, [contracts])

  const getByContractById = async (id: number) => {
    try {
      const { data } = await api.get(`management/contract/last-created/${id}`)
      setContracts(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getCredentials = async () => {
    try {
      openToggle()
      const { data } = (await api.get('monitoring/credential/client')) as any
      setCredentials(data)
    } catch (e: any) {
      const messageError = Object?.values(e?.response?.data || {})?.toString()
      toastError('Erro ao cadastrar empresa', messageError)
    } finally {
      closeToggle()
    }
  }

  useEffect(() => {
    if (company?.id) {
      getByContractById(company.id)
      getCredentials()
    }
  }, [company?.id])

  const resetInputValue = () => {
    setInputValue({ ...initialState, company: company?.id })
  }

  const handleValues = async (target: any) => {
    const { id, value } = target
    await setInputValue({ ...inputValue, [id]: value })
  }

  const handleValuesEdit = async (credential: any) => {
    openToggle()
    await setInputValue(credential)
    closeToggle()
  }

  const updateCredentials = async () => {
    const { id, credentials } = inputValue as any
    try {
      await api.patch(`monitoring/credential/client/${id}`, { credentials })
      await getCredentials()
    } catch (e: any) {
      const messageError = Object?.values(e?.response?.data).toString()
      toastError('Erro ao cadastrar empresa', messageError)
    }
  }

  const createCredential = async () => {
    try {
      await api.post('monitoring/credential/client', inputValue)
    } catch (e: any) {
      const messageError = Object.values(e.response.data).toString()
      toastError('Erro ao cadastrar empresa', messageError)
    }
  }

  const handleMessageError = (key: any) => {
    setError((prev: any) => ({ ...prev, [key]: false }))
  }

  const validateValue = () => {
    setError(validetValueError(inputValue))
    const status = emptyInput(inputValue) as boolean
    if (!status) {
      toastError('Por favor!', 'Preencha os campos em vermelho')
    }
    return status
  }

  return (
    <CredentialsContext.Provider
      value={{
        isFetching,
        updateCredentials,
        allCredentialsAreValid,
        areAnyCredentialsValid,
        credentials,
        createCredential,
        getCredentials,
        handleMessageError,
        handleValues,
        handleValuesEdit,
        resetInputValue,
        validateValue,
        getByContractById,
        isComprasNetCredentialValid,
        error,
        inputValue,
        contracts,
        isValidContract,
        contractEndsIn,
        isFreeContract,
      }}
    >
      {children}
    </CredentialsContext.Provider>
  )
}

const useCredentials = () => {
  return useContext(CredentialsContext)
}

export { CredentialsProvider, useCredentials }
