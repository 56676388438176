import { useUser } from 'hooks/useUser'
import { PropsWithChildren, useEffect } from 'react'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import { routes } from 'utils/constants'

const RequireAuth = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { loginState } = useUser()
  const accountValidationMatch = useMatch(routes.ACC_VALIDATION_EMAIL)
  const resetPasswordMatch = useMatch(routes.RESET_PASSWORD)
  const loginMatch = useMatch(routes.LOGIN)

  useEffect(() => {
    if (loginState === 'UNLOGGED') {
      if (!accountValidationMatch && !resetPasswordMatch && !loginMatch) {
        const currentPath = location.pathname + location.search + location.hash
        console.log('Redirecting to login', currentPath)
        const redirect = encodeURIComponent(currentPath)
        navigate(`${routes.LOGIN}?redirect=${redirect}`)
      }
    }
  }, [accountValidationMatch, loginState, loginMatch, navigate, resetPasswordMatch])

  return <>{children}</>
}

export default RequireAuth
