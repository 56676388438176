import { generatePath, UIMatch } from 'react-router-dom'
import { routes } from './constants'

/**
 * Retorna a chave do objeto routes que corresponde ao caminho atual.
 * @param matches utilizado para obter os parâmetros da rota. Para consegui use const matches = useMatches()
 * @returns keyof typeof routes
 */
export const getCurrentRouteId = (matches: UIMatch[]): keyof typeof routes | undefined => {
  const { pathname: currentPathname } = matches.reduce(
    (currentUrl, match) => new URL(match.pathname, currentUrl.href),
    new URL('http://exemple.com/')
  )

  const params: Record<string, string> = matches.reduce((params, match) => ({ ...params, ...match.params }), {})
  let key: keyof typeof routes | undefined
  if (Object.keys(params).length > 0) {
    const paramsProxy = new Proxy(params, {
      get: (target, prop) => {
        if (typeof prop === 'symbol') return '[symbol]'
        if (target[prop]) {
          return target[prop]
        }
        return prop
      },
    })
    key = (Object.keys(routes) as (keyof typeof routes)[]).find(
      key => currentPathname === generatePath(routes[key], paramsProxy)
    )
  } else {
    key = (Object.keys(routes) as (keyof typeof routes)[]).find(key => currentPathname === routes[key])
  }
  return key
}
