import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import api from 'services/api'
import { PortalsTypes } from 'types/portalsTypes'
import { handleImgPortals } from 'utils/constants'
import { useCompanies } from './useCompanies'
import { useToast } from './useToast'
import { useCredentials } from './useCredentials'

const PortalContext = createContext<any>(null)

const PortalProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [portals, setPortals] = useState<PortalsTypes[]>([])
  const [portalsAll, setPortalsAll] = useState<PortalsTypes[]>([])
  const { company } = useCompanies()
  const { toastError } = useToast()

  const filterPortalId = (id: number) => {
    return portals?.find(portal => portal?.id === id) || false
  }

  useEffect(() => {
    if (company) {
      getPortal()
      getPortalAll()
    }
  }, [company?.id])

  const getPortal = async () => {
    setLoading(true)
    try {
      const { data } = await api.get('monitoring/portals')
      setPortals(
        data
          ?.filter((portal: PortalsTypes) => portal?.is_monitored)
          ?.map((portal: PortalsTypes) => ({ ...portal, image: handleImgPortals[portal?.id || 1] }))
      )
    } catch (e: any) {
      const messageError = Object?.values(e?.response?.data)?.toString()
      toastError('Erro ao buscar portal', messageError || 'Erro ao buscar portal')
    } finally {
      setLoading(false)
    }
  }
  const getPortalAll = async () => {
    setLoading(true)
    if (company?.id) {
      try {
        const { data } = (await api.get('monitoring/portals')) as any
        setPortalsAll(data?.sort((a: any, b: any) => a.name.localeCompare(b.name)))
      } catch (e: any) {
        toastError('Erro ao buscar portal', e?.response?.data?.message || 'Erro ao buscar portal')
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <PortalContext.Provider value={{ loading, portalsAll, getPortal, portals, filterPortalId }}>
      {children}
    </PortalContext.Provider>
  )
}

const usePortal = () => {
  return useContext(PortalContext)
}

export { PortalProvider, usePortal }
