// eslint-disable-next-line import/no-duplicates
import { format, fromUnixTime, subMonths } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale'

export interface MonthOption {
  id: number
  text: string
}
export const getLastThreeMonths = (): MonthOption[] => {
  return Array.from({ length: 3 }, (_, index) => {
    const date = subMonths(new Date(), index)
    return {
      id: index + 1,
      text: format(date, 'MMMM yyyy', { locale: ptBR }),
    }
  })
}
export const formatDateTimeStamp = (date: number) => {
  return date ? format(fromUnixTime(date), 'dd/MM/yyy - HH:mm') : '------------------'
}

export const formatDateTimeStampSecond = (date: number) => {
  return date ? format(fromUnixTime(date), 'dd/MM/yy HH:mm:ss') : '------------------'
}

export const formatDateTimeStampAs = (date: number) => {
  return date ? format(fromUnixTime(date), 'dd/MM/yy HH:mm') : '----------------'
}

export const formatDateTimeStampAsX = (date: number) => {
  return date ? format(fromUnixTime(date), 'dd/MM/yyyy ') : '------------------'
}

export const formatDate = (date: number) => {
  return date ? format(fromUnixTime(date), 'dd/MM/yy') : '------------------'
}

export const formatDateToCalc = (date: number) => {
  return date ? format(fromUnixTime(date), 'MM-dd-yyyy') : '------------------'
}

export const formatDateBr = (date: any) => {
  return date ? format(date, 'dd/MM/yyyy') : '------------------'
}
export const formatDateBR = (date: any, formart: string) => {
  return date ? format(date, formart) : '------------------'
}
